// constants/dormList.ts
import { Dorm } from '../Models/Dorm'

export const DormData: Dorm[] = [
    {
      LongName: "Newman Hall",
      ShortName: "newman",
      exteriorImageUrl: "https://ik.imagekit.io/abm/Outside_Newman_Ge0TfZTNk.jpg",
      interiorImageUrl: "",
      address: "200 Kent St, Blacksburg, VA",
      yearBuilt:1964,
    },
    {
      LongName: "Slusher Hall",
      ShortName: "slusher",
      exteriorImageUrl: "https://ik.imagekit.io/abm/Slusher_Outside_hOJe8aDN6.jpg",
      interiorImageUrl: "",
      address: "201 Ag Quad Lane, Blacksburg, VA",
      yearBuilt:1972,
    },
    {
      LongName: "CID (The Creativity and Innovation District Residence Hall)",
      ShortName: "cid",
      exteriorImageUrl: "https://ik.imagekit.io/abm/CID_V3TWAaI8q.jpg",
      interiorImageUrl: "",
      address: "185 Kent St, Blacksburg, VA",
      yearBuilt:2021,
    }, 
    {
      LongName: "Peddrew-Yates",
      ShortName: "peddrew-yates",
      exteriorImageUrl: "https://ik.imagekit.io/abm/peddrew_tjNeLo0GX.jpg?updatedAt=1726384227312",
      interiorImageUrl: "",
      address: "610 Washington St SW, Blacksburg, VA",
      yearBuilt:1998,
    },
    {
      LongName: "Ambler Johnston (East)",
      ShortName: "east-aj",
      exteriorImageUrl: "https://ik.imagekit.io/abm/Ambler_Johnson_East_Ya0TQNFRN.jpg",
      interiorImageUrl: "",
      address: "700 Washington St SW, Blacksburg, VA",
      yearBuilt:1969,
    },
    {
      LongName: "Johnson Hall",
      ShortName: "johnson",
      exteriorImageUrl: "https://ik.imagekit.io/abm/Johnson_Hal_7l3KuCfZF.jpg",
      interiorImageUrl: "",
      address: "500 Washington St SW Blacksburg, VA",
      yearBuilt:1965,
    },
    {
      LongName: "Payne Hall",
      ShortName: "payne",
      exteriorImageUrl: "https://ik.imagekit.io/abm/Payne_I3ISWyKcy.jpg",
      interiorImageUrl: "",
      address: "600 Washington St SW, Blacksburg, VA",
      yearBuilt:1994,
    },
    {
      LongName: "Hoge Hall",
      ShortName: "hoge",
      exteriorImageUrl: "https://ik.imagekit.io/abm/Hoge_ZS1wuQnQK.jpg",
      interiorImageUrl: "",
      address: "570 Washington St SW, Blacksburg, VA",
      yearBuilt:1966,
    },
    {
    LongName: "Graduate Life Center at Donaldson-Brown",
    ShortName: "glc",
    exteriorImageUrl: "https://ik.imagekit.io/abm/GLC_U6dxxxQnZ.jpg",
    interiorImageUrl: "",
    address: "570 Washington St SW, Blacksburg, VA",
    yearBuilt:1968,
  },

  {
    LongName: "Main/West/East Eggleston Hall",
    ShortName: "egg",
    exteriorImageUrl: "https://ik.imagekit.io/abm/Egg_k4E5apNQr.jpg",
    interiorImageUrl: "",
    address: "500 Drillfield Dr, Blacksburg, VA",
    yearBuilt:1940,
  },
  

  {
    LongName: "O’Shaughnessy Hall",
    ShortName: "oshaughnessy",
    exteriorImageUrl: "https://ik.imagekit.io/abm/OShaughnessy__o6sgVfP8.jpg",
    interiorImageUrl: "",
    address: "530 Washington St SW, Blacksburg, VA",
    yearBuilt:1966,
  },
  {
    LongName: "Cochrane Hall",
    ShortName: "cochrane",
    exteriorImageUrl: "https://ik.imagekit.io/abm/Campbell_uUy869Sgj.jpg",
    interiorImageUrl: "",
    address: "770 Washington St, Blacksburg, VA",
    yearBuilt:1983,
  },
  {
    LongName: "Harper Hall",
    ShortName: "harper",
    exteriorImageUrl: "https://ik.imagekit.io/abm/Harper_kCEDkvuRO.jpg",
    interiorImageUrl: "",
    address: "240 West Campus Dr, Blacksburg, VA",
    yearBuilt:1999,
  },
  {
  LongName: "New Hall West",
  ShortName: "new-hall-w",
  exteriorImageUrl: "https://ik.imagekit.io/abm/nhw_DmiY4_TlQ.jpg",
  interiorImageUrl: "",
  address: "770 Washington St, Blacksburg, VA",
  yearBuilt:2009,
},
{
LongName: "New Residence Hall East",
ShortName: "new-res",
exteriorImageUrl: "https://ik.imagekit.io/abm/New_Residence_East_IwIPDdaRF.jpg",
interiorImageUrl: "",
address: "590 Washington St SW, Blacksburg, VA",
yearBuilt:1998,
},
    {
      LongName: "Miles Hall",
      ShortName: "miles",
      exteriorImageUrl: "https://ik.imagekit.io/abm/Miles_-O5slF74B.jpg",
      interiorImageUrl: "",
      address: "410 Washington St SW, Blacksburg, VA",
      yearBuilt:1964,
      },

      {
        LongName: "Main/East Campbell",
        ShortName: "campbell",
        exteriorImageUrl: "https://ik.imagekit.io/abm/Campbell_uUy869Sgj.jpg",
        interiorImageUrl: "",
        address: "300 Drillfield Dr, Blacksburg, VA",
        yearBuilt:1940,
        },
        {
          LongName: "Pritchard Hall",
          ShortName: "pritchard",
          exteriorImageUrl: "https://ik.imagekit.io/abm/Pritchard_RR8rNvxJY.png",
          interiorImageUrl: "",
          address: "630 Washington St SW, Blacksburg, VA",
          yearBuilt:1967,
          },
          {
            LongName: "Vawter Hall",
            ShortName: "vawter",
            exteriorImageUrl: "https://ik.imagekit.io/abm/Vawter_dCpG3QZaG.png",
            interiorImageUrl: "",
            address: "180 Kent St, Blacksburg, VA",
            yearBuilt:1962,
            },
            {
              LongName: "Whitehurst Hall",
              ShortName: "whitehurst",
              exteriorImageUrl: "https://ik.imagekit.io/abm/Whitehurst_hall_sE_uGQQWp.png",
              interiorImageUrl: "",
              address: "240 Kent St, Blacksburg, VA",
              yearBuilt:1962,
              },
  ]

