import React from 'react'
import './Spinner.css'


const Spinner: React.FC = () => (
    <div className="spinner-container z-50">
      <div className="spinner"></div>
    </div>
  );
  


export default Spinner;
