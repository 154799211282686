import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import { withAuthInfo, useLogoutFunction, useRedirectFunctions } from '@propelauth/react'

type Props = {}

export const ErrorPage = withAuthInfo((props) => {
  const logoutFunction = useLogoutFunction()
  const { redirectToLoginPage, redirectToSignupPage, redirectToAccountPage } = useRedirectFunctions()
  const navigation = [
    { name: 'Dashboard', href: 'dashboard/dorms'},
    { name: 'About', href: 'about' },
    { name: 'GitHub', href: 'https://github.com/turboslapper/hokiehomes' }
  ]
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}
      <Navbar navigation={navigation} redirectToLoginPage={() => redirectToLoginPage()} redirectToSignupPage={() => redirectToSignupPage()} redirectToAccountPage={() => redirectToAccountPage()} useLogoutFunction={() => logoutFunction(true)} isLoggedIn={props.isLoggedIn}/>
      
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-orange-600">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
          <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn’t find the page you’re looking for.</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="/"
              className="rounded-md bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
            >
              Go back home
            </a>
            <a href="#" className="text-sm font-semibold text-gray-900">
              Contact support <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </main>
    </>
  )
})